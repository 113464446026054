<template>
  <component :is="realComponent" :pageData="mainPageData" v-if="realComponent && mainPageData"></component>
  <PageHeadWatcher :mainHeadData="mainHeadData"/>
</template>

<script setup lang="ts">
import {onPageLoad} from "./web_page";
import {definePageMeta} from "#imports";
import PageHeadWatcher from "./_page_head_watcher.vue";

definePageMeta({
  layout: "web"
});

const {mainPageData, loadPage, realComponent, mainHeadData} = onPageLoad();


await loadPage();
</script>
